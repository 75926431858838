import Confirm from '@/components/confirms/Confirm.vue';
import SmartExport from '@/components/forms/common/SmartExport';
import AddCategory from '@/views/protected/school_management/components/AddCategory.vue';

import ResetPassword from '@/components/forms/ResetPassword.vue';
import CreateAcademicYear from '@/components/forms/CreateAcademicYear.vue';
import AddMultipleAcademicYears from '@/components/forms/AddMultipleAcademicYears.vue';
import CreateEnrollmentType from '@/components/forms/CreateEnrollmentType.vue';
import CreateGrade from '@/components/forms/CreateGrade.vue';
import CreateSection from '@/components/forms/CreateSection.vue';
import CreateSubject from '@/components/forms/CreateSubject.vue';
import CreateCourse from '@/components/forms/CreateCourse.vue';
import UserRegistrationConfirmation from '@/components/card/UserRegistrationConfirmation.vue';
import ChangeProfileStatus from '@/components/card/ChangeProfileStatus.vue';
import ChangeSchoolStatus from '@/components/card/ChangeSchoolStatus.vue';
import ConfirmResetPassword from '@/components/card/ResetPasswordConfirmation.vue';
import UserSelector from '@/components/card/user_selector/UserSelector.vue';
import SectionTransfer from '@/components/card/SectionTransfer.vue';
import MassTransfer from '@/components/card/MassTransfer.vue';
import MassUpdate from '@/components/card/MassUpdate.vue';

import CourseSelector from '@/components/card/course_selector/CourseSelector.vue';
import TeacherList from '@/components/card/TeacherList.vue';
import CreateLibraryCategory from '@/components/card/CreateLibraryCategory.vue';
import LibraryCategories from '@/views/protected/digital_library/LibraryCategorySidebar.vue';
import AddDigitalBook from '@/views/protected/digital_library/AddBook.vue';
import AddRole from '@/components/forms/AddRole.vue';
import AddGuardian from '@/components/forms/AddGuardian.vue';
import AllDetails from '@/components/forms/AllDetails.vue';

import CreateLabCategory from '@/components/card/CreateLabCategory.vue';
import LabCategorySidebar from '@/views/protected/digital_lab/LabCategorySidebar.vue';
import AddSimulation from '@/views/protected/digital_lab/AddSimulation.vue';
import CreateEvent from '@/views/protected/schedule/CreateEvent.vue';
import EventDetail from '@/views/protected/schedule/EventDetail.vue';
import InsertRemark from '@/views/protected/attendance/InsertRemark.vue';
import CreateScheduleSession from '@/views/protected/schedule/components/CreateSession.vue';
import WorkingDays from '@/views/protected/schedule/components/WorkingDays.vue';
import ScheduleGenerationReport from '@/views/protected/schedule/components/ScheduleGenerationReport.vue';
import GenerateSchedule from '@/views/protected/schedule/components/GenerateSchedule.vue'; //
import EditCourseConfig from '@/views/protected/course/components/EditCourseConfig.vue';
import AddAssessment from '@/components/forms/AddAssessment.vue';
import EditAssessment from '@/components/forms/EditAssessment.vue';

import GradeVerificationDetail from './../../components/forms/GradeVerificationDetail.vue';
import EditAcademicStatus from '@/components/card/EditAcademicStatus.vue';
import PrintRoaster from '../forms/PrintRoaster.vue';
import PrintGradeReport from '../forms/PrintGradeReport.vue';
import PrintId from '../forms/PrintId.vue';
import SchoolDetails from '../forms/SchoolDetails.vue';

import Readmission from '@/components/card/ReadmissionStatus.vue';
import Withdrawn from '@/components/card/WithdrawnStatus.vue';

// library

import AddShelf from '@/views/protected/library_management/shelf/Create.vue';
import AddBookCategory from '@/views/protected/library_management/book_category/Create.vue';
import AddBook from '@/views/protected/library_management/book/Create.vue';
import AddBookCopy from '@/views/protected/library_management/book_copy/Create.vue';

import AddIssue from '@/views/protected/library_management/issue/Create.vue';
import ApproveIssue from '@/views/protected/library_management/issue/components/Approve.vue';
import ReturnIssue from '@/views/protected/library_management/issue/components/Return.vue';
import AddAdjustment from '@/views/protected/library_management/adjustment/Create.vue';
import AdjustmentDetail from '@/views/protected/library_management/adjustment/Detail.vue';
import IssueDetail from '@/views/protected/library_management/issue/Detail.vue';

// fee

import AddPaymentType from '@/components/forms/fee/AddPaymentType.vue';
import AddPaymentAmount from '@/components/forms/fee/AddPaymentAmount.vue';
import AddPayer from '@/components/forms/fee/AddPayer.vue';
import AddGroup from '@/components/forms/fee/AddGroup.vue';
import AddDiscount from '@/components/forms/fee/AddDiscount.vue';
import SearchSelect from './../../components/forms/SearchSelect.vue';

let configs = [
  {
    id: 1,
    component: Confirm,
    width: 500,
  },
  {
    id: 2,
    component: ResetPassword,
    width: 500,
  },
  {
    id: 3,
    component: UserRegistrationConfirmation,
    width: 800,
  },
  {
    id: 4,
    component: ChangeProfileStatus,
    width: 500,
  },
  {
    id: 5,
    component: ConfirmResetPassword,
    width: 500,
  },
  {
    id: 6,
    component: ChangeSchoolStatus,
    width: 500,
  },
  {
    id: 7,
    component: UserSelector,
    width: 600,
  },
  {
    id: 8,
    component: CreateAcademicYear,
    width: 600,
  },
  {
    id: 9,
    component: CreateGrade,
    width: 600,
  },
  {
    id: 10,
    component: CreateSection,
    width: 600,
  },
  {
    id: 11,
    component: CourseSelector,
    width: 600,
  },
  {
    id: 12,
    component: CreateCourse,
    width: 600,
  },
  {
    id: 13,
    component: TeacherList,
    width: 600,
  },
  {
    id: 14,
    component: CreateLibraryCategory,
    width: 600,
  },
  {
    id: 15,
    component: LibraryCategories,
    width: 300,
    variant: 'aside',
  },
  {
    id: 16,
    component: AddDigitalBook,
    width: 900,
  },
  {
    id: 17,
    component: CreateLabCategory,
    width: 600,
  },
  {
    id: 18,
    component: LabCategorySidebar,
    width: 300,
    variant: 'aside',
  },
  {
    id: 19,
    component: AddSimulation,
    width: 900,
  },
  {
    id: 20,
    component: CreateEvent,
    width: 600,
  },
  {
    id: 21,
    component: EventDetail,
    width: 600,
  },
  {
    id: 22,
    component: InsertRemark,
    width: 600,
  },
  {
    id: 23,
    component: CreateScheduleSession,
    width: 600,
  },
  {
    id: 24,
    component: ScheduleGenerationReport,
    width: 700,
  },
  {
    id: 25,
    component: EditCourseConfig,
    width: 600,
  },
  {
    id: 26,
    component: GenerateSchedule,
    width: 600,
  },
  {
    id: 27,
    component: AddRole,
    width: 400,
  },
  {
    id: 28,
    component: AddPaymentType,
    width: 600,
  },
  {
    id: 29,
    component: AddPaymentAmount,
    width: 600,
  },
  {
    id: 30,
    component: AddPayer,
    width: 600,
  },
  {
    id: 31,
    component: AddGroup,
    width: 600,
  },
  {
    id: 32,
    component: AddDiscount,
    width: 600,
  },
  {
    id: 33,
    component: AddCategory,
    width: 600,
  },
  {
    id: 34,
    component: AddGuardian,
    width: 1100,
  },
  {
    id: 35,
    component: SmartExport,
    width: 680,
  },
  {
    id: 36,
    component: CreateSubject,
    width: 600,
  },
  {
    id: 37,
    component: SearchSelect,
    width: 600,
  },
  {
    id: 38,
    component: WorkingDays,
    width: 600,
  },
  {
    id: 39,
    component: AllDetails,
    width: 1300,
  },
  {
    id: 40,
    component: AddMultipleAcademicYears,
    width: 600,
  },
  {
    id: 41,
    component: CreateEnrollmentType,
    width: 600,
  },
  {
    id: 42,
    component: SectionTransfer,
    width: 400,
  },
  {
    id: 43,
    component: GradeVerificationDetail,
    width: 1300,
  },
  {
    id: 44,
    component: PrintRoaster,
    width: 1500,
  },

  {
    id: 45,
    component: PrintId,
    width: 900,
  },
  {
    id: 46,
    component: PrintGradeReport,
    width: 1300,
  },
  {
    id: 47,
    component: MassTransfer,
    width: 1200,
  },
  {
    id: 48,
    component: MassUpdate,
    width: 1300,
  },
  {
    id: 49,
    component: EditAcademicStatus,
    width: 400,
  },
  {
    id: 50,
    component: Readmission,
    width: 450,
  },
  {
    id: 51,
    component: Withdrawn,
    width: 450,
  },
  {
    id: 52,
    component: SchoolDetails,
    width: 1200,
  },

  {
    id: 53,
    component: AddAssessment,
    width: 600,
  },
  {
    id: 54,
    component: EditAssessment,
    width: 600,
  },
  {
    id: 55,
    component: AddShelf,
    width: 600,
  },

  {
    id: 56,
    component: AddBookCategory,
    width: 600,
  },
  {
    id: 57,
    component: AddBook,
    width: 700,
  },
  {
    id: 58,
    component: AddBookCopy,
    width: 600,
  },
  {
    id: 59,
    component: AddIssue,
    width: 800,
  },
  {
    id: 60,
    component: AddAdjustment,
    width: 600,
  },
  {
    id: 61,
    component: ApproveIssue,
    width: 800,
  },
  {
    id: 62,
    component: ReturnIssue,
    width: 800,
  },
  {
    id: 63,
    component: IssueDetail,
    width: 800,
  },
  {
    id: 64,
    component: AdjustmentDetail,
    width: 800,
  },
];
import { validateConfig } from './configValidator';

export default validateConfig(configs) ? configs : [];
